import requestV2 from '@services/api'

export const getSavedCards = async (limit: number = 100) => {
  const { data } = await requestV2(`/users/saved-cards?limit=${limit}`, 'GET')

  return data
}

export const removeSavedCard = async (sourceId: string) => {
  await requestV2(`/users/saved-cards/${sourceId}`, 'DELETE', {
    data: {}
  })
}

export const storeUserTelemetry = async <T>(clientTelemetryData: T) => {
  await requestV2('/users/storeUserTelemetry', 'POST', {
    data: {
      ...clientTelemetryData
    }
  })
}
