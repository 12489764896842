import { useMemo } from 'react'

import { isNil } from 'lodash-es'

import { useQuery } from '@tanstack/react-query'

import { QueryClientKeysEnum } from '@enums'

import { ApplicationConfigService } from '@elitecompetitions/client-api'

export function useApplicationConfig() {
  const { data: applicationConfig = null } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_APPLICATION_CONFIG],
    queryFn: () =>
      ApplicationConfigService.getApplicationConfig({
        tenantId: 'default'
      })
  })

  const roundUpActive = useMemo(() => {
    if (isNil(applicationConfig)) {
      return false
    }

    const { roundUpActive = false } = applicationConfig

    return roundUpActive
  }, [applicationConfig])

  const walletMoneyBackPercentage = useMemo(() => {
    if (isNil(applicationConfig)) {
      return 0
    }

    const { walletMoneyBackPercentage = 0 } = applicationConfig

    return walletMoneyBackPercentage
  }, [applicationConfig])

  const showApplicationBanner = useMemo(() => {
    if (isNil(applicationConfig)) {
      return false
    }

    const { showApplicationBanner = false } = applicationConfig

    return showApplicationBanner
  }, [applicationConfig])

  return {
    roundUpActive,
    showApplicationBanner,
    walletMoneyBackPercentage
  }
}
