import axios, {
  type AxiosResponse,
  type AxiosRequestConfig,
  type InternalAxiosRequestConfig,
  type AxiosError,
  HttpStatusCode
} from 'axios'

import { isNil } from 'lodash-es'

import { v4 as uuid } from 'uuid'

import config from '@services/config'

import { getAppPlatform, getCookies } from '@utils'

import { authStoreFactory } from '@store/Auth'

import { API_TIMEOUT_MS } from '@helpers/constants'
import { isBrowser } from '@helpers/isBrowser'

export const legacyApi = axios.create()

legacyApi.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const cookies = getCookies()

    if (cookies._fbp) {
      config.headers['fbp'] = cookies._fbp
    }

    config.headers['platform'] = getAppPlatform()
    config.headers['X-Request-Id'] = uuid()

    const accessToken = await authStoreFactory().getToken()

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return config
  }
)
legacyApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (!isNil(error)) {
      const { response } = error

      if (!isNil(response)) {
        const { status } = response

        if (
          isBrowser() &&
          (status === HttpStatusCode.Forbidden ||
            status === HttpStatusCode.Unauthorized)
        ) {
          await authStoreFactory().logout()

          window.location.replace(`/login?referrer=${window.location.pathname}`)
        }
      }
    }

    return Promise.reject(error)
  }
)
legacyApi.defaults.timeout = API_TIMEOUT_MS

const requestV2 = async (
  url: string,
  method = 'GET',
  options: AxiosRequestConfig = {}
) => {
  const response = await legacyApi.request({
    baseURL: config.api.v2,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    url,
    method,
    ...options
  })

  return response
}

export default requestV2
