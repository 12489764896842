import { isNil } from 'lodash-es'

import { useMemo } from 'react'

import { useRouter } from 'next/router'

import Marquee from 'react-fast-marquee'

import { useQuery } from '@tanstack/react-query'

import sanitize from '@utils/sanitize'

import config from '@services/config'

import { QueryClientKeysEnum, RoutesEnum } from '@enums'

import { ApplicationBannersService } from '@elitecompetitions/client-api'

import {
  StyledTickerText,
  StyledTickerContainer
} from './BannerPromotion.styled'
import { type IBannerPromotionProps } from './BannerPromotion.types'

const BannerPromotion = (props: IBannerPromotionProps) => {
  const { showApplicationBanner = false } = props

  const router = useRouter()

  const { pathname } = router

  const { data: bannerPromotionText = '' } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_PROMOTION_BANNER_TEXT],
    queryFn: () => ApplicationBannersService.getApplicationBanner(),
    select: data => data?.content || ''
  })

  const showBannerPromotion = useMemo(() => {
    return (
      showApplicationBanner &&
      bannerPromotionText !== '' &&
      (pathname.includes(RoutesEnum.ACTIVE_COMPETITIONS) ||
        pathname.includes(RoutesEnum.INSTAWIN_COMPETITIONS) ||
        pathname.includes(RoutesEnum.PENNY_COMPETITIONS) ||
        pathname === RoutesEnum.HOME)
    )
  }, [showApplicationBanner, bannerPromotionText, pathname])

  const sanitizedBannerPromotionText = useMemo(() => {
    return sanitize(bannerPromotionText)
  }, [bannerPromotionText])

  /**
   * Extracts the first URL (if exist) from an `<a href="">` in a banner text.
   */
  const firstUrlFromBannerText = useMemo(() => {
    const urlPattern = /<a href="([^"]+)"/

    const urls = bannerPromotionText.match(urlPattern)

    return urls ? urls[1] : null
  }, [bannerPromotionText])

  const handleNavigate = async () => {
    if (isNil(firstUrlFromBannerText)) {
      return
    }

    /**
     * Regular expression (3 groups) to match URLs that belong to 'elitecompetitions.co.uk' or its subdomains.
     */
    const domainRegex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*elitecompetitions\.co\.uk(\/.*)?$/
    const match = domainRegex.exec(firstUrlFromBannerText)

    if (match && match[3]) {
      /**
       * If the URL matches the domain or subdomains of 'elitecompetitions.co.uk' and contains a relative path, redirect to that relative path.
       */
      await router.push(match[3])
    } else {
      /**
       * If the URL does not match the domain or its subdomains, redirect to an external resource.
       */
      window.location.href = firstUrlFromBannerText
    }
  }

  if (!showBannerPromotion) {
    return null
  }

  return (
    <StyledTickerContainer
      isBlackFriday={config.events.isBlackFriday}
      isClickable={!isNil(firstUrlFromBannerText)}
      onClick={handleNavigate}
    >
      <Marquee
        autoFill
        gradient
        gradientWidth={20}
        speed={20}
        direction="left"
        gradientColor="transparent"
        pauseOnHover
      >
        <StyledTickerText
          dangerouslySetInnerHTML={{
            __html: sanitizedBannerPromotionText
          }}
        />
      </Marquee>
    </StyledTickerContainer>
  )
}

export default BannerPromotion
