export const localStorageWithTTL = {
  setItem<T>(key: string, value: T, ttlInSeconds: number) {
    if (typeof window !== 'undefined') {
      const item = {
        value,
        expiresAt: Date.now() + ttlInSeconds * 1000 // Convert ttlInSeconds to milliseconds
      }

      localStorage.setItem(key, JSON.stringify(item))
    }
  },

  getItem(key: string) {
    if (typeof window !== 'undefined') {
      const item = JSON.parse(localStorage.getItem(key))

      if (item && Date.now() < item.expiresAt) {
        return item.value
      }

      this.removeItem(key)
      // Item has expired or doesn't exist
      return null
    }

    return null
  },

  removeItem(key: string) {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key)
    }
  }
}
